import * as React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Contact from "../components/Contact/Contact"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import "../styles/bootstrap.scss"

const contatti = () => {
  return (
    <>
      <Seo title="Contatti" />
      <Layout>
        <SimpleBanner title="Restiamo in contatto"  slogan="Scrivici e raccontaci cosa ti serve">
          <StaticImage
            className="banner__image"
            src="../../static/evergreensabina.jpg"
            alt="Apple Macbook Dark"
          />
        </SimpleBanner>
         <Contact /> 
       {/*  <h3 className="margin-auto mt-5 text-primary text-center">In allestimento</h3> */}
      </Layout>
    </>
  )
}

export default contatti
